import React  from 'react'
// import { Link } from 'gatsby'
// import github from '../img/github-icon.svg'
// import logo from '../img/logo.svg'
import {Spring} from 'react-spring/renderprops'
import TransitionLink from 'gatsby-plugin-transition-link'
import { MorphLink } from './LinkCardMorph'
// import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
// import SpringCard from '../components/SpringCard'





const Navbar = ({showSlogan, location}) => {
  // const { morph, setMorph } = useContext( MorphContext );
  // const [morph, setMorph] = useState({});
  // const { mount, transitionStatus } = useTransitionState();
  // console.log(useTransitionState());
  // const mount = false;

  let slug = null;
  if( location ) {
    let pathname = location.pathname;
    if (pathname.endsWith('/'))
      slug = pathname.slice(0, -1).split('/').pop();
    else
      slug = pathname.split('/').pop();
  }

  const isAbout = slug === 'about';
  return (

      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
        style={{"zIndex": "1"}}
      >

        <div className="container">

          <div className="left">

            <MorphLink
              to='/'
              id={slug}
              duration={isAbout ? 1 : 1.5}
              isReverse={true}
            >
              Malena Melendez
            </MorphLink>


            <Spring
              from={{ opacity: 0 }}
              to={{opacity: showSlogan ? 1 : 0}}>
              { props => <div className="slogan" style={props}>Product designer and baker extraordinaire</div> }
            </Spring>

          </div>

          <div className="right">

            <Spring
              from={{ opacity: 0 }}
              to={{opacity: showSlogan || isAbout ? 1 : 0}}>
              { props => (
                  <TransitionLink to="/about" title="Home"
                    exit={{
                      length: 1,
                    }}
                    entry={{
                      length: 1,
                      delay: 1
                    }}
                    className={isAbout ? 'active' : ''}
                    style={props}
                  >
                    About
                  </TransitionLink>
              )}
            </Spring>

          </div>

        </div>

      </nav>


  )

}

export default Navbar
