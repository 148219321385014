import React, { useState }  from 'react'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import Navbar from './Navbar'
// import Confetti from './Confetti'
// import Perrito from './OverlayLottie'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import {Transition} from 'react-spring/renderprops'
import LinkCardMorph, { MorphContext } from './LinkCardMorph'



const TemplateWrapper = ({ children, location }) => {
  // console.log(footer);
  const isHome = location ? location.pathname === '/' : true;
  const [morph, setMorph] = useState({});
  const { title, description } = useSiteMetadata()
  // console.log('Layout Render');
  // <html lang="en" style={'overflow-y: ' + (morph.show ? 'hidden' : 'scroll') + '; margin-right: ' + (morph.show ? '15px' : '0px') + ';' }/>

  return (
    <div>
      <Helmet>
        <html lang="en"/>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no"></meta>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>

      <MorphContext.Provider value={{ morph, setMorph }}>

        <Navbar showSlogan={isHome} location={location}/>

        {children}

        <Transition
          items={true}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}>
          { isHome => isHome && (props => <div style={props}><Footer /></div>) }
        </Transition>

      </MorphContext.Provider>

      { morph.show ?
        <LinkCardMorph img={morph.img} bg={morph.bg} from={morph.from} to={morph.to} duration={morph.duration} isReverse={morph.isReverse} />
      : null }

      {/* <Confetti/> */}
      {/* <Perrito/> */}
    </div>
  )
}

export default TemplateWrapper
