import React from 'react'
// import useWindowSize from '../hooks/useWindowSize'
import Lottie from 'react-lottie'
import animationData from '../lotties/perritosfullscreen.json'
import {Transition} from 'react-spring/renderprops'


const OverlayLottie =  ({show}) => {
  // const { width, height } = useWindowSize()
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'none'
    }
  };

  return(
    <Transition
    items={show}
    from={{ opacity: 0 } }
    enter={{ opacity: 1 , config:{ duration : 1000}}}
    leave={{ opacity: 0 }}>
    {show => show && (props =>
      <div className='is-overlay' style={{ zIndex: 5, position:'fixed', ...props}}>
      <Lottie options={defaultOptions}
          isClickToPauseDisabled={true}
          speed={1}
      />
      </div>
    )}
  </Transition>

  )
}


export default OverlayLottie