import React from 'react'
import { useSpring, animated } from 'react-spring'
// import Lottie from 'react-lottie'
// import animationData from '../lotties/perritosfullscreen.json'
// import Img from "gatsby-image"
// import BackgroundImage from 'gatsby-background-image'
import PCBackgroundImage from './PCBackgroundImage'
import './LinkCard.sass'

const calc = (x, y, s) => [-(y - window.innerHeight / 2) / 400, (x - window.innerWidth / 2) / 400, s]
// const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
const trans = (x, y, s) => `scale(${s})`


const LinkCard = ({bg, img, style, id, className, scale, isHovering}) => {

  // if (!bg) return null;

  if (!scale)
    scale = 1.02;

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: 'none'
  //   }
  // };
  // const Media = () => {
  //   if(img.isAnimated) {
  //     return (
  //       <Lottie
  //         options={defaultOptions}
  //         isClickToPauseDisabled={true}
  //         speed={1}
  //       />
  //     )

  //   } else {
  //     return (
  //       <img src={img}/>
  //     )
  //   }
  // }

  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 950, friction: 20 } }))
  if(isHovering === true)
    set({ xys: [0, 0, scale] })
  else if(isHovering === false)
    set({ xys: [0, 0, 1] })

  return (
    <animated.div
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y, scale) })}
      onMouseLeave={() => set({ xys: [0, 0, isHovering ? scale : 1] })}
      onMouseDown={() => set({ xys: [0, 0, isHovering ? scale : 1] })}
      // onMouseUp={() => set({ xys: [0, 0, 1.05] })}
      id={id}
      style={style}
      className={className}
    >
      <div className='linkCard'>
        <div className='aspect-ratio-box-inside'>
          <PCBackgroundImage
            className="bg"
            img={bg}
            style={{backgroundAttachment: 'scroll', backgroundSize: '100% 100%'}}
          >

            <animated.div style={{ transform: props.xys.interpolate(trans), height: img.height, display: 'flex', overflow:"hidden"}}>
              {/* <Media/> */}
              {/* <Img fluid={img.childImageSharp.fluid}/> */}
              <img src={img.url || img.src || img.childImageSharp.fluid.src} style={{objectFit: 'contain'}}/>
            </animated.div>

          </PCBackgroundImage>
        </div>
      </div>

    </animated.div>
  )
}

export default LinkCard;