import React, { createContext, useState, useContext} from 'react'
import TransitionLink, { TransitionPortal }  from 'gatsby-plugin-transition-link'
import {useSpring, animated, config} from 'react-spring'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import LinkCard from './LinkCard'
import * as easings from 'd3-ease'

// export const getOffsetCenter = (width, height) => {
//   const wHeight = window.innerHeight;
//   const wWidth = window.innerWidth;

//   const centerX = ( (wWidth/2) - (width/2) );
//   const centerY = ( (wHeight/2) - (height/2) );
//   return {x: centerX, y: centerY}
// };

export const MorphContext = createContext({});


export const MorphLink = ({to, id, img, bg, title, description, duration, isReverse, arrowDirection, className, children}) => {
  const [hover, setHover] = useState();
  const { morph, setMorph } = useContext( MorphContext );

  const opts = {
    id: id,
    setter: setMorph,
    img: img || morph.img,
    bg: bg || morph.bg,
    isReverse: isReverse,
    duration: duration || 2
  }

  const isPrev = arrowDirection === 'left';
  let classNames = '';
  if (arrowDirection === 'right' || isPrev) {
    classNames += 'miniLink ';
    if (isPrev)
      classNames += 'is-pulled-left';
    else
      classNames += 'is-pulled-right';
  }

  // if mobile make animations faster
  if (typeof window !== `undefined`) {
    if (window.innerWidth < 768 ) {
      duration = Math.round(duration * .75);
    }
  }


  return (
    <TransitionLink to={to}
      onMouseEnter={(e) => setHover(true)}
      onMouseLeave={(e) => setHover(false)}
      onMouseDown={(e) => setHover(false)}
      exit={{
        length: duration,
      }}
      entry={{
        appearAfter: duration,
        length: duration,
        state: {
          fromSlug: id
        }
      }}
      trigger={(pages) => transitionTrigger(pages, opts)}
      className={ className }
    >

      { arrowDirection ?
        <div>
          <PreviewCompatibleImage imageInfo={{image: isPrev ? '/img/arrow-left@2x.png' : '/img/arrow-right@2x.png' }} style={{width: '49px'}} />
          <p>{title}</p>
        </div>
      : null }


      { children ?
        children
      :
        <LinkCard img={img} bg={bg} id={id} isHovering={hover} className={classNames}></LinkCard>
      }

      { !arrowDirection && !children ?
        <p>
          <b>{title}</b> <br></br>
          {description}
        </p>
      : null }

    </TransitionLink>
  )
}

// export const MorphLink = React.memo( MorphLink2, () => true);


export const transitionTrigger = async (pages, {id, setter, img, bg, isReverse, duration} ) => {
  let scrollTo = 0;
  // const from = '#morph-' + fromId;
  // const to = '#morph-' + toId;

  // wait until we have access to both pages
  const exit = await pages.exit
  const entry = await pages.entry
  // here we can access both pages
  // start exit animation based on measurements if you want
  const morphProps = getMorphProps(exit, entry, '#'+id);

  if(morphProps){

    if(img && bg) {
      morphProps.img = img;
      morphProps.bg = bg;
    }
    morphProps.duration = duration;
    morphProps.isReverse = isReverse || false;
    // morphProps.from.el.style.display = 'none';
    morphProps.from.el.style.opacity = '0';

    // adjusts scroll and y if object is down out of view
    if (morphProps.to.y > window.innerHeight) {
      const margin = 150;
      const y = window.innerHeight - ( morphProps.to.height + margin ) ;
      scrollTo = morphProps.to.y - y;
      morphProps.to.y = y;
    }

    morphProps.show = true;
    setter(morphProps);

    // hiding after animation timing since react-spring gives no onEnd, only onRest which happens before duration ends
    setTimeout(() => {
      setter({...morphProps, show:false});
    }, (duration * 1000) + 100)

  };

  await entry.visible;
  window.scrollTo(0, scrollTo);

}


export const getMorphProps = (exit, entry, id) => {
  let morphProps = {};
  const morphFromEl = exit.node.querySelector(id);
  const morphToEl = entry.node.querySelector(id);

  if(!morphToEl || !morphFromEl) return null;

  const morphFromRect = morphFromEl.getBoundingClientRect();
  const morphToRect = morphToEl.getBoundingClientRect();

  let touch = false;
  let height = 0;
  if (typeof window !== 'undefined') {
    touch = 'ontouchstart' in document.documentElement;
    height = document.documentElement.scrollHeight;
  }
  // if( morphToRect.y < 0 ) {
    morphToRect.y =  morphToRect.y + window.scrollY;
  // }

  if( morphFromRect.x < 0 ) {
    morphFromRect.x +=  (!touch) ? window.innerWidth-15 : window.innerWidth;
  }
  if (morphToRect.width === 0) {
    const parentRect = morphToEl.parentElement.getBoundingClientRect()
    morphToRect.width = parentRect.width;
    morphToRect.height = parentRect.height;
  }
  morphProps = {
    from: {el: morphFromEl, x: morphFromRect.x, y: morphFromRect.y, width: morphFromRect.width, height: morphFromRect.height},
    to: {x: morphToRect.x, y: morphToRect.y, width: morphToRect.width, height: morphToRect.height},
  }
  // console.log(morphProps);

  return morphProps;
}


const LinkCardMorph = ({from, to, img, bg, duration, isReverse, onRest}) => {

  // if( !to || !from || !img ) return null;
  const moveProps = {
    top: to.y,
    left: to.x,
  };

  const scaleProps = {
    width: to.width,
    height: to.height
  };

  const toProps = !isReverse ? [moveProps, scaleProps] : [scaleProps, moveProps];

  const segmentDuration = Math.round((duration/2) * 1000);

  // console.log(segmentDuration);
  const morph = useSpring({
    config: { duration: segmentDuration, easing:easings.easeCubic },
    // config: config.gentle,
    to: toProps,
    from: {
      opacity: 1,
      position:'fixed',
      top: from.y,
      left: from.x,
      width: from.width,
      height: from.height
    },
    onRest: onRest,
  })

  const AnimatedLinkCard = animated(LinkCard)

  return (
    <TransitionPortal>
      <AnimatedLinkCard img={img} bg={bg} style={{...morph}}></AnimatedLinkCard>
    </TransitionPortal>
  )
}

export default LinkCardMorph


