import React from 'react'
import BackgroundImage from 'gatsby-background-image'

export default function PCBackgroundImage({children, className, img, style}) {

  if (typeof img === 'string' || img.url || img.src) {
    const src = img.url || img.src || img;
    return (
      <div
        className={className}
        style={{
          backgroundPosition: 'center',
          backgroundRepeat:	'no-repeat',
          backgroundSize:	'cover',
          backgroundImage: "url('" + src + "')",
          ...style}}
      >
        {children}
      </div>
    )
  }
  else if (img.childImageSharp) {
    return (
      <BackgroundImage
        className="bg"
        fluid={img.childImageSharp.fluid}
        style={{backgroundAttachment: 'scroll', backgroundSize: '100% 100%'}}
      >
        {children}
      </BackgroundImage>
    )
  }
  else {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
  }


}
